import * as React from "react"
import { Link, graphql } from "gatsby"
import { linkResolver } from '../utils/link_resolver'
import Seo from '../components/seo'
import Footer from '../components/footer'
import Header from '../components/header'
import { GatsbyImage, StaticImage } from 'gatsby-plugin-image'
import { useState } from 'react'
import ProductReviews from '../components/product_reviews'

const Cats = ({data}) => {
  const entry = data.prismicCats
  const currency = new Intl.NumberFormat('en-NZ', {
    style: 'currency',
    currency: 'NZD',
    minimumFractionDigits: 2
  })

  const [isReviewsOpen, setReviewsOpen] = useState(false)
  const reviewsHandler = () => {
    setReviewsOpen(true)
    //console.log('reviewsHandler')
  }

  if (!entry) return null
  return (
    <>
      <ProductReviews isReviewsOpen={isReviewsOpen} setReviewsOpen={setReviewsOpen} />
      <Seo title={entry.data.page_title} description={entry.data.meta_description} image="" pathname={linkResolver(entry.data)} />
      <Header />

      <div className="w-full bg-diamond rounded-br-[133px]">
        <div className="flex-none md:flex justify-between">
          <div className="w-full md:w-6/12 relative">
            <div className="absolute z-10 top-1/2 -left-16 transform -translate-y-1/2 origin-center -rotate-90">
              <button onClick={() => reviewsHandler()} className="rounded-full px-9 py-3 font-poppins text-lg font-medium bg-diamond text-white">
                <div className="flex flex-row items-center gap-3">
                  <div>Reviews</div>
                  <div className="text-lg">★</div>
                </div>
              </button>
            </div>
            <GatsbyImage className="relative w-full h-full block object-cover py-12 md:py-0" image={entry.data.image.gatsbyImageData} alt={entry.data.image.alt ?? ''} />
          </div>
          <div className="w-full md:w-6/12 px-16 py-16 md:py-36">
            <div className="leading-snug text-2xl text-white font-poppins font-semibold">
              <h2 className="mb-8">
                <span className="bg-white text-3xl text-orange px-3 py-1 font-bold leading-tight inline-block">{entry.data.tag1}</span>
              </h2>
              <p className="ml-3">{entry.data.heading}</p>
            </div>
          </div>
        </div>
      </div>

      <div className={entry.data.personalisation_text !== null ? "w-full pt-4 pb-16 bg-white relative" : "w-full pt-12 pb-16 bg-white relative"}>
        <div className="w-9/12 mx-auto max-w-screen-xl relative z-20 pb-4">
          <div className="flex flex-col md:flex-row justify-between items-center gap-3">
            <div className="font-semibold font-poppins text-diamond">                  
              {entry.data.personalisation_text !== null ? 
                <div className="flex items-center gap-3">
                  <div>
                    <div className="w-12 h-12 rounded-full p-1 bg-diamond">
                      <StaticImage className="w-full h-full object-cover block" src="../images/personalisation_white.png" alt="" />
                    </div>
                  </div>
                  <div>
                    <p>{entry.data.personalisation_text}</p>
                  </div>
                </div>
                :
                <div></div>
              }
            </div>
            <div></div>
          </div>
        </div>
        {(entry.uid !== 'tags') ? 
        <div className="absolute z-10 right-0 bottom-12 md:right-12 bottom-24 opacity-10">
          <img className="w-36 h-24 md:w-60 md:h-48 -rotate-45" src="/img/paw_print.svg" alt="" />  
        </div> : ''}
        <div className="z-20 w-9/12 mx-auto max-w-screen-xl relative">
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
            {entry.data.entries.map((entry, i) => {
              return (
                <div key={i} className="p-8 rounded-tr-[27px] rounded-tl-[27px] rounded-bl-[27px] border-2 border-diamond-50">
                  <Link to={linkResolver(entry.page_link1)} className="flex flex-col justify-between h-full">
                    <figure>
                      <GatsbyImage className="object-scale-down w-full h-full block rounded-tr-[27px] rounded-tl-[27px] rounded-bl-[27px]" image={entry.image1.gatsbyImageData} alt={entry.image1.alt ?? ''} />
                    </figure>
                    {entry.type1 !== 'none' &&
                    <div className="mt-6">
                      {entry.type1 === 'extras' && <span className="font-poppins bg-pink px-2 py-1 font-semibold text-sm text-white capitalize">
                        Extras
                      </span>}
                      {entry.type1 === 'training' && <span className="font-poppins bg-blue px-2 py-1 font-semibold text-sm text-white capitalize">
                        Training
                      </span>}
                      {entry.type1 === 'everyday' && <span className="font-poppins bg-diamond-50 px-2 py-1 font-semibold text-sm text-white capitalize">
                        Everyday
                      </span>}
                      {entry.type1 === 'fashion' && <span className="font-poppins bg-pink px-2 py-1 font-semibold text-sm text-white capitalize">
                        Fashion
                      </span>}
                      {entry.type1 === 'robust' && <span className="font-poppins bg-green px-2 py-1 font-semibold text-sm text-white capitalize">
                        Robust
                      </span>}
                    </div>
                    }
                    <div className="font-poppins text-orange font-semibold text-xl leading-tight mt-6">
                      <h3>{entry.heading1} &#62;</h3>
                    </div>
                    <div className="font-poppins font-medium text-sm text-diamond-80 mt-3 leading-snug">
                      <p>{entry.text1}</p>
                    </div>
                    <div className="font-poppins font-medium text-lg text-diamond-80 mt-3 mb-6 leading-snug">
                      <p>From {currency.format(entry.min_price_nzd) && currency.format(entry.min_price_nzd)} NZD</p>
                    </div>
                    <div className="flex items-end justify-end">
                      {entry.personalisation !== false ? 
                        <div className="w-12 h-12 rounded-full p-1 bg-diamond">
                          <StaticImage className="w-full h-full object-cover block" src="../images/personalisation_white.png" alt="" />
                        </div>
                        :
                        <div className="w-12 h-12 rounded-full p-1 bg-transparent">
                          &nbsp;
                        </div>
                      }
                    </div>
                  </Link>
                </div>
              )
            })}
          </div>
        </div>
      </div>

      <div className="w-full relative">
        <div className="absolute -inset-0">
          <GatsbyImage className="rounded-tl-[133px] w-full h-full block object-cover" image={entry.data.review_image.gatsbyImageData} alt={entry.data.review_image.alt ?? ''} />
        </div>
        <div className="absolute inset-0">
          <div className="flex items-end h-full">
            <div className="w-8/12">
              &nbsp;
            </div>
            <div className="w-3/12 h-full">
              <div className="w-full h-full block bg-gradient-to-l from-black to-transparent">
                &nbsp;
              </div>
            </div>
            <div className="w-3/12 h-full bg-black">
              &nbsp;
            </div>
          </div>
        </div>
        <div className="w-10/12 mx-auto max-w-screen-xl">
          <div className="flex-none md:flex justify-between relative">
            <div className="w-full md:w-6/12">
              &nbsp;
            </div>
            <div className="w-full md:w-6/12 pt-12 pb-12 md:pt-72 md:pb-24 text-right">
              <div className="font-poppins leading-tight text-xl md:text-2xl font-semibold">
                <h2 className="text-white mb-6"><em>“{entry.data.review_quote}”</em></h2>
                <p className="text-orange">- {entry.data.review_author}</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  )
}

export const query = graphql`
query($handle: String) {
  prismicCats(uid: {eq: $handle}) {
    uid
    data {
      page_title
      meta_description
      tag1
      heading
      image {
        alt
        gatsbyImageData
      }
      personalisation_text
      entries {
        personalisation
        type1
        image1 {
          alt
          gatsbyImageData
        }
        heading1
        text1
        page_link1 {
          url
          uid
          type
          link_type
        }
        min_price_nzd
      }
      review_image {
        alt
        gatsbyImageData
      }
      review_quote
      review_author
    }
  }
}
`

export default Cats
